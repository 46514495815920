<template>
<div class="page-proveedores">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Proveedores" class="elevation-1 px-5 py-3">

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="success" @click.native="modalImportar=true" title="Importar Proveedores" v-tippy>
              <v-icon dark>mdi-file-import</v-icon>
            </v-btn>
            &nbsp;
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="agregar" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                <template slot="fecha_alta" slot-scope="props">
                  {{get_fecha(props.row.fecha_alta)}}
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="get_proveedor(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="green" @click.native="get_proveedor_articulos(props.row)" title="Agregar Artículos" v-tippy>
                      <v-icon>shopping_cart</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="eliminar(props.row)" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="55%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Proveedor</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? actualizar() : guardar()">
            <v-row>
              <v-col sm="7" md="7" lg="7">
                <v-text-field label="Nombre" v-model="model.nombre" :rules="[rules.required]" @input="upperCaseInput('nombre')"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                <v-text-field label="RFC" v-model="model.rfc" :rules="[rules.required, rules.rfc]" @input="upperCaseInput('rfc')"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="Alta" v-model="model.fecha_alta" disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Teléfono Empresa 1" v-model="model.telefono_empresa_1"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Teléfono Empresa 2" v-model="model.telefono_empresa_2"></v-text-field>
              </v-col>      
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Giro" v-model="model.giro" :rules="[rules.required]"></v-text-field>
              </v-col>        
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Asesor de Ventas" v-model="model.contacto" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Teléfono Asesor" v-model="model.telefono_1" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Email Asesor" v-model="model.email" :rules="[rules.required, rules.email]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Supervisor" v-model="model.supervisor"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Teléfono Supervisor" v-model="model.telefono_2"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Email Supervisor" v-model="model.email2" :rules="[rules.email]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Gerente" v-model="model.gerente"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Teléfono Gerente" v-model="model.telefono_3"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4">
                <v-text-field label="Email Gerente" v-model="model.email3" :rules="[rules.email]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Condiciones" v-model="model.condiciones" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Días de Plazo para entrega de pedido" v-model="model.plazo" 
                  persistent-hint hint="Este valor se utiliza en las compras para sugerir cantidad a pedir"
                  :rules="[rules.required, validaCantidadEntera]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8" md="8" lg="8">
                <v-text-field label="Domicilio" v-model="model.domicilio" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="Ext." v-model="model.numero_exterior" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="Int." v-model="model.numero_interior"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6" lg="6">
                <v-text-field label="Colonia" v-model="model.colonia" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                <v-text-field label="Ciudad" v-model="model.ciudad" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="8" md="8" lg="8">
                <v-text-field label="Referencias" v-model="model.referencias" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-text-field label="CP" v-model="model.codigo_postal" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="2" md="2" lg="2">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? actualizar() : guardar()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalImportar" max-width="39%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-import</v-icon>&nbsp;
          <span class="subheading">
            <strong>Importar Proveedores - Relación Artículos</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalImportar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>

        <v-container grid-list-md>
          <v-row justify="center">
            <v-col sm="6" md="6" lg="6">
              Importar Proveedores
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel :data="camposLayout" :exportFields="encabezadoLayout" name="Plantilla_Proveedores.xlsx">
                <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Proveedores" v-tippy>
                  <v-icon dark>receipt</v-icon>
                </v-btn>
              </exportar-excel>
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <upload-excel-component title="Importar Proveedores" idInput="proveedoresKey" :on-success="handleSuccessProveedores" :before-upload="beforeUpload" />
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel v-show="1==2" :data="proveedores_all" :exportFields="encabezadoLayout" name="Proveedores.xlsx">
                <v-btn id="botonExportarProveedores" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>
              <v-btn fab dark x-small color="green darken-2" title="Exportar Proveedores" @click="getProveedoresExportarAll()" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
            </v-col>
          </v-row>

          <v-row>
            &nbsp;
          </v-row>

          <v-row justify="center">
            <v-col sm="6" md="6" lg="6">
              Importar Relación de Articulos del Proveedor
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel :data="camposLayoutArticulos" :exportFields="encabezadoLayoutArticulos" name="Plantilla_AticulosProveedor.xlsx">
                <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Articulos Proveedor" v-tippy>
                  <v-icon dark>receipt</v-icon>
                </v-btn>
              </exportar-excel>
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <upload-excel-component title="Importar Relación de Articulos del Proveedor" idInput="relacionKey" :on-success="handleSuccessArticulosProveedores" :before-upload="beforeUpload" />
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel v-show="1==2" :data="articulos_relacion" :exportFields="encabezadoLayoutArticulos" name="Proveedores_Articulos.xlsx">
                <v-btn id="botonExportarRelacion" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>
              <v-btn fab dark x-small color="green darken-2" title="Exportar Relación Artículos" @click="getProveedoresArticulosAll()" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
            </v-col>
          </v-row>

        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalImportar = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog v-model="modal_articulos" max-width="100%">
      <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Asignar Artículos al Proveedor: {{model.nombre}}</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_articulos = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container fluid fill-height>
              <v-layout row wrap>
                  <v-flex col xs6 s6 m6 l6>
                      <h4><v-icon>list</v-icon> Artículos Activos</h4>
                      <!--v-row>
                        <v-col sm="10" md="10" lg="10">
                            <v-autocomplete style="font-size:9px !important;" v-model="unidades_medida_filtro" :items="unidades_medida" :hide-no-data="true"
                                multiple small-chips deletable-chips label="Filtrar por unidad de medida">
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="2" md="2" lg="2">
                            <v-btn small x-small dark color="blue darken-4"
                                @click="getArticulosActivos(1)"
                                title="Filtrar Unidades" v-tippy> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row-->
                        <v-divider />
                      <v-card-text>
                      <v-client-table id="table_izquierda" ref="vuetable_articulos_all" :data="articulos_lista_all" :columns="columns_lista" :options="options_lista" class="elevation-2 pa-2">
                          <template slot="codigo_barras" slot-scope="props">
                              <div style="font-size:10px !important;" @dblclick="seleccionar(props.row)">{{props.row.codigo_barras.join(", ")}}</div>
                          </template>
                          <template slot="nombre" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="seleccionar(props.row)">{{props.row.nombre}}</div>
                          </template>
                          <template slot="descripcion" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="seleccionar(props.row)">{{props.row.descripcion}}</div>
                          </template>
                          <template slot="categoria" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="seleccionar(props.row)">{{props.row.categoria}}</div>
                          </template>
                          <template slot="actions" slot-scope="props">
                            <div style="text-align:center;">
                              <v-icon x-small color="success" @click.native="seleccionar(props.row)">fa-forward</v-icon>
                          </div>
                        </template>
                      </v-client-table>
                    </v-card-text>
                  </v-flex>

                  <v-flex col xs6 s6 m6 l6 style="border-left: 1px solid; !important" >
                      <h4><v-icon>check</v-icon> Artículos del Proveedor </h4>
                      <v-divider />                      
                      <v-card-text>
                          <v-client-table id="table_derecha" ref="vuetable_articulos_proveedor" :data="articulos.items" :columns="columns_lista_derecha" :options="options_lista_derecha" class="elevation-2 pa-2">
                            <template slot="codigo_barras" slot-scope="props">
                                <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.codigo_barras.join(", ")}}</div>
                            </template>
                            <template slot="nombre" slot-scope="props">
                              <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.nombre}}</div>
                            </template>
                            <template slot="descripcion" slot-scope="props">
                              <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.descripcion}}</div>
                            </template>
                            <template slot="categoria" slot-scope="props">
                            <div style="font-size:10px !important;" @dblclick="remover_articulo(props.row)">{{props.row.categoria}}</div>
                          </template>
                          <template slot="actions" slot-scope="props">
                            <div style="text-align:center;">
                                <v-icon x-small color="error" @click.native="remover_articulo(props.row)">fa-backward</v-icon>
                            </div>
                          </template>
                        </v-client-table>
                      </v-card-text>
                  </v-flex>
              </v-layout>
          </v-container>
  </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    search(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length < 2) return
      // Si todavía esta buscando
      if (this.isLoading) return
      this.isLoading = true
      let data = {
        "selector": {
          "type":"articulos",
          "nombre": {
            "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          },
          "estatus": "Activo"
        },
        "sort": ["nombre"],
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos_encontrados = response.data.docs;
          } else
            this.articulos_encontrados = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos.",
            footer: ""
          });
        }).then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    
  }, 
  mounted: function() {
    if (!this.verificaPermiso('t.catalogos.proveedores')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
    window.dialogLoader.show('Espere un momento por favor..');
    this.model = this.clean_model();
    //this.getUnidadesMedida();
    //this.unidades_medida_filtro = ["BULTO","Caja","CAJA","CHAROLA","CUBETA","PACA","Paquete","PAQUETE"];
    this.getCategorias();
    this.getArticulosActivos(1).then(result => {
      window.dialogLoader.hide();
    }).catch(error => {
        window.dialogLoader.hide();
        this.$swal({
          type: "error",
          title: "¡Operación no Permitida!",
          text: "Ocurrió un error al obtener los artículos.",
          footer: error
        });
      });
  },
  data() {
    return {
      unidades_medida_filtro:[],
      unidades_medida:[],
      filtro_articulo:"",
      modalImportar:false,
      modal_articulos_copy:false,
      camposObligatorios: [
          "nombre",
          "rfc",
          "telefono_1",
          "contacto",
          "giro",
          "plazo",
          "email",
          "condiciones",
          "domicilio",
          "numero_exterior",
          "colonia",
          "ciudad",
          "referencias",
          "codigo_postal",
          "estatus"
      ],
      encabezadoLayout: {
          nombre: "nombre",
          rfc: "rfc",
          telefono_empresa_1: "telefono_empresa_1",
          telefono_empresa_2: "telefono_empresa_2",
          giro: "giro",

          contacto: "contacto",
          telefono_1: "telefono_1",
          email: "email",
          supervisor: "supervisor",
          telefono_2: "telefono_2",
          email2: "email2",
          gerente: "gerente",
          telefono_3: "telefono_3",
          email3: "email3",
          
          condiciones: "condiciones",
          plazo: "plazo",
          
          domicilio: "domicilio",
          numero_exterior: "numero_exterior",
          numero_interior: "numero_interior",
          colonia: "colonia",
          ciudad: "ciudad",
          referencias: "referencias",
          codigo_postal: "codigo_postal",
          estatus:"estatus",
      },
      //van vacíos para solo descargar layout con encabezados
      camposLayout: [{
          nombre: "[Nombre del proveedor]",
          rfc: "[RFC del proveedor]",
          telefono_empresa_1: "[Telefono Empresa1 - Opcional]",
          telefono_empresa_2: "[Telefono Empresa2 - Opcional]",
          giro: "[Giro del proveedor]",

          contacto: "[Nombre del Asesor de Ventas]",
          telefono_1: "[Telefono Asesor]",
          email: "[Email Asesor]",
          supervisor: "[Nombre del Supervisor - Opcional]",
          telefono_2: "[Telefono Supervisor - Opcional]",
          email2: "[Email Supervisor - Opcional]",
          gerente: "[Nombre del Gerente - Opcional]",
          telefono_3: "[Telefono Gerente - Opcional]",
          email3: "[Email Gerente - Opcional]",
          
          condiciones: "[Condiciones]",
          plazo: "[Cantidad de días de plazo para entrega de pedido]",
          
          domicilio: "[Calle]",
          numero_exterior: "[Número Exterior]",
          numero_interior: "[Número Interior - Opcional]",
          colonia: "[Colonia]",
          ciudad: "[Ciudad]",
          referencias: "[Referencias del domicilio]",
          codigo_postal: "[Código Postal]",
          estatus:"[Estatus - Activo|Inactivo]"
      }],
      camposObligatoriosArticulos: [
          "rfc",
          "codigo_barras",
      ],
      encabezadoLayoutArticulos: {
          rfc: "rfc",
          codigo_barras: "codigo_barras",
      },
      //van vacíos para solo descargar layout con encabezados
      camposLayoutArticulos: [{
          rfc: "[RFC del proveedor]",
          codigo_barras: "[Código de Barras del Artículo]",
      }],
      proveedores_all:[],
      articulos_all:[],
      articulos_lista_all:[],
      articulos_relacion:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      search: "",
      model_filters: "",
      columns_lista: [
          "codigo_barras","nombre","descripcion","categoria",
          "actions"
        ],
      columns_lista_derecha: [
          "actions",
          "codigo_barras","nombre","descripcion","categoria",
      ],
      options_lista: {
        filterable: ["codigo_barras","nombre", "descripcion","categoria"],
        sortable: ["codigo_barras","nombre", "descripcion","categoria"],
        perPage: 25,
        pagination: {
          show: true
        },
        headings: {
          nombre: "Artículo",
          "descripcion": "Descripción",
          "categoria":"Categoría",
          actions: "Agregar"
        },
        listColumns:{
          categoria:[],
        }
      },
      options_lista_derecha: {
        filterable: ["codigo_barras","nombre", "descripcion","categoria"],
        sortable: ["codigo_barras","nombre", "descripcion","categoria"],
        perPage: 25,
        pagination: {
          show: true
        },
        headings: {
          nombre: "Artículo",
          "descripcion": "Descripción",
          "categoria":"Categoría",
          actions: "Remover"
        },
        listColumns:{
          categoria:[],
        }
      },

      articulos: {
        columns: [
          "codigo_barras",
          "nombre",
          "descripcion",
          "actions"
        ],
        options: {
          filterable: ["codigo_barras", "nombre", "marca"],
          sortable: ["codigo_barras", "nombre", "marca"],
          perPage: 10,
          pagination: {
            show: true
          },
          headings: {
            "codigo_barras": "Código",
            nombre: "Nombre",
            "marca": "Marca",
            actions: "Operaciones"
          },
        },
        items: []
      },
      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Catálogos",
          disabled: true,
          href: ""
        },
        {
          text: "Proveedores",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "nombre",
        "rfc",        
        "contacto",
        "telefono_1",
        "email",
        "fecha_alta",
        "estatus",
        "actions"
      ],
      options: {
        filterable: ["nombre", "rfc", "telefono_1", "contacto", "email", "estatus"],
        sortable: ["nombre", "rfc", "telefono_1", "contacto", "email", "fecha_alta", "estatus"],

        headings: {
          nombre: "Nombre",
          rfc: "RFC",
          telefono_1: "Telefono Asesor",
          contacto: "Asesor Ventas",
          email: "Email Asesor",
          fecha_alta: "Fecha Alta",
          estatus: "Estatus",
          actions: "Operaciones"
        },        
        listColumns: {
              estatus: [{
                      id: 'Activo',
                      text: "Activo"
                  },
                  {
                      id: 'Inactivo',
                      text: "Inactivo"
                  }
              ],              
          },
      },
      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if(value && value!="")
            return pattern.test(value) || "Correo electrónico no valido";
          else 
            return true;
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    onStatusChanged(event) {
      //const selectedStatus = event.target.value;
      //console.log('Se ha seleccionado una nueva opción:', selectedStatus);
      this.items = [];
      this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    getCategorias: function() {
      let data = {
        "selector": {
          "type": "categorias",
          "estatus": {
            "$eq": "Activo"
          }
        },
        "fields": [
          "nombre", "_id"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.sort(this.ordenar_nombre);
            this.categorias = response.data.docs;        
            this.options_lista.listColumns["categoria"] = this.categorias.map(function(item) {
                    return {"id":item.nombre,"text":item.nombre}
                });    
                this.options_lista_derecha.listColumns["categoria"] = this.options_lista.listColumns["categoria"];                
          } else
            this.categorias = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las categorias.",
            footer: ""
          });
        });
    },
    ordenar_nombre: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this.model[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this.model[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"proveedores"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(response => {
          if (response.data.docs.length) {
            this.items = this.items.concat(response.data.docs);
            for(var k in this.items){
              this.items[k].articulos= 0;
            }
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getUnidadesMedida: function() {
        this.unidades_medida = [];
        var self = this;
        var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/unidades?group=true';
        axios.get(url)
        .then(response => {
            if (response.data.rows.length > 0) {
                response.data.rows.forEach(function(e){
                    self.unidades_medida.push(e.key);
                });
            }
        })
        .catch(error => {
            console.log(error)
        });
    },
    clean_model: function() {
      return {
        _id: "",
        _rev: "",
        type: "proveedores",
        nombre: "",
        rfc: "",
        telefono_empresa_1: "",
        telefono_empresa_2: "",
        contacto: "",
        telefono_1: "",
        email: "",
        supervisor:"",
        telefono_2: "",
        email2: "",
        gerente:"",
        telefono_3: "",
        email3: "",        
        condiciones:"",        
        giro: "",        
        domicilio: "",
        plazo: "",
        numero_exterior: "",
        numero_interior: "",
        colonia: "",
        ciudad: "",
        referencias: "",
        codigo_postal: "",
        fecha_alta: window.moment(window.moment().format("YYYY-MM-DDTHH:mm:ss")).format("DD-MM-YYYY"),
        estatus: "Activo",
      };
    },

    get_proveedor: function(id) {
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id)
        .then(response => {
          this.model = response.data;
          this.editar();
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    get_proveedor_articulos: function(row) {
      window.dialogLoader.show('Espere un momento por favor..');
      this.model = row;

      var busca = encodeURI(escape(row._id));
      window.axios
      .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
      .then(async responseArticulo => {

          if(responseArticulo.data != null && responseArticulo.data.rows!=null && responseArticulo.data.rows.length>0){
            var rows = responseArticulo.data.rows;
            this.articulos.items = [];
            for(var k in rows){
              var record = {
                "_id": rows[k].value._id,
                "nombre": rows[k].value.nombre,
                "codigo_barras": rows[k].value.codigo_barras,
                "descripcion": rows[k].value.descripcion,
                "categoria": rows[k].value.categoria.join(", "),
              };
              this.articulos.items.push(record);
            }
          } else {
            this.articulos.items = [];
          }
          //this.model = response.data;
          this.editar_articulos();
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos",
            footer: ""
          });
        }).then(()=>{
          window.dialogLoader.hide();
        });
    },
    agregar: function() {
      this.model = this.clean_model();
      this.update = false;
      this.modal = true;
    },
    editar: function() {
      this.update = true;
      this.modal = true;
      this.tmp_fecha = this.model.fecha_alta;
      this.model.fecha_alta = window.moment(this.model.fecha_alta).format("DD-MM-YYYY")
    },
    editar_articulos: function() {
      this.update = true;
      this.modal_articulos = true;
      this.search = "";
    },
    guardar: function() {
      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then(result => {
          let data = {
            type: this.model.type,
            nombre: this.model.nombre.toString().trim(),
            rfc: this.model.rfc.toString().trim(),
            telefono_empresa_1: this.model.telefono_empresa_1 != null ? this.model.telefono_empresa_1.toString().trim() : "",
            telefono_empresa_2: this.model.telefono_empresa_2 != null ? this.model.telefono_empresa_2.toString().trim() : "",
            giro: this.model.giro != null ? this.model.giro.toString().trim() : "",
            contacto: this.model.contacto.toString().trim(),
            telefono_1: this.model.telefono_1 != null ? this.model.telefono_1.toString().trim() : "",
            email: this.model.email != null ? this.model.email.toString().trim() : "",
            supervisor: this.model.supervisor != null ? this.model.supervisor.toString().trim() : "",
            telefono_2: this.model.telefono_2 != null ? this.model.telefono_2.toString().trim() : "",
            email2: this.model.email2 != null ? this.model.email2.toString().trim() : "",
            gerente: this.model.gerente != null ? this.model.gerente.toString().trim() : "",
            telefono_3: this.model.telefono_3 != null ? this.model.telefono_3.toString().trim() : "",
            email3: this.model.email3 != null ? this.model.email3.toString().trim() : "",
            condiciones: this.model.condiciones != null ? this.model.condiciones.toString().trim() : "",
                        
            
            plazo: this.model.plazo.toString().trim(),
            domicilio: this.model.domicilio,
            numero_exterior: this.model.numero_exterior,
            numero_interior: this.model.numero_interior != null ? this.model.numero_interior.toString().trim() : "",
            colonia: this.model.colonia != null ? this.model.colonia.toString().trim() : "",
            ciudad: this.model.ciudad != null ? this.model.ciudad.toString().trim() : "",
            codigo_postal: this.model.codigo_postal != null ? this.model.codigo_postal.toString().trim() : "",
            referencias: this.model.referencias != null ? this.model.referencias.toString().trim() : "",
            fecha_alta: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
            estatus: this.model.estatus,
          };

          window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
            .then(response => {
              this.modal = false;
              window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
              });
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              console.log(error);
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar el registro.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();
            });
        }).catch(err => {
          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },

    actualizar: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.validaUnicos().then(result => {
          let data = this.model;
          data.fecha_alta = this.tmp_fecha;
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                color: 'error'
              });
            }).then(() => {
              this.modal = false;
              window.dialogLoader.hide();
            });
        }).catch(err => {
          window.dialogLoader.hide();
          var mensaje = "";
          if (err.length > 1)
            mensaje = err.join(', ') + " ya existen. Favor de verificar.";
          else
            mensaje = err.join(', ') + " ya existe. Favor de verificar.";

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: mensaje,
            footer: ""
          });
        });
      }
    },
    eliminar: async function(row) {
      var _id = row._id;
      var _rev = row._rev;

      let self = this;
            window.dialogLoader.show('Espere un momento por favor..');
            let data = {
                  "selector": { "type":"compras", 
                    "fecha":{"$exists":true},
                    "proveedor.id_proveedor":_id,
                    "estatus":{"$exists":true}, },
                  "fields": [ "_id"],
                  "use_index": "_design/9ba1fd5b4f91ea09721f40698ce2d01db5a07383",
                  "limit":1
              };

            await  window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(async response => {
                  //console.log(response.data);
                  if(response.data.docs.length > 0){
                    window.dialogLoader.showSnackbar('El proveedor contiene compras imposible eliminarlo.', { color: 'error' } );
                    window.dialogLoader.hide();
                  }else{
                    window.dialogLoader.hide();
                    this.$swal({
                      type: "info",
                      title: "Cuidado",
                      text: "¿Está seguro de eliminar el registro: "+row.nombre+" ("+row.rfc+")?",
                      footer: "",
                      showCancelButton: true,
                      cancelButtonColor: "#d33",
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "Aceptar",
                      cancelButtonText: "Cancelar"
                    }).then(result => {
                      if (result.value ) {
                        window.dialogLoader.show('Espere un momento por favor..');
                        window.axios
                          .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
                          .then(response => {
                            window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                              color: 'success'
                            });
                            this.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                          })
                          .catch(error => {
                            window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                              color: 'error'
                            });

                          }).then(() => {
                            window.dialogLoader.hide();
                          });

                      }

                    });
                  }
                })
                .catch(error => {
                      console.log(err);
                });

    },

    validaUnicos: async function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var duplicados = [];
        await self.validaRFC().then(result => {}).catch(err => {
          duplicados.push("RFC");
        });

        if (duplicados.length > 0)
          return reject(duplicados);
        else
          return resolve(true);

      });

    },
    validaCantidadEntera: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad entera no válida"
      } else
        return true;
    },
    validaRFC: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {
        if (self.model.rfc != null && self.model.rfc != "") {
          var busca = encodeURI(escape(self.model.rfc.toString().trim().toLowerCase()));
          window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/proveedores/_view/rfc?key=\"' + busca + '\"')
            .then(response => {

              if (response.data != null && response.data.rows != null && response.data.rows.length > 0) {
                if (self.model._id != "") { //Esta editando
                  var filter = response.data.rows.find(r => {
                    return self.model._id != r.id;
                  });
                  if (filter) {
                    return reject(false);
                  }
                } else { //Es nuevo
                  return reject(false);
                }
              }
              return resolve(true);
            })
            .catch(err => {
              return reject(false);
            });
        } else
          return resolve(true);
      });
    },

    get_fecha: function(fecha) {
      try {
        return window.moment(fecha).format("DD-MM-YYYY");
      } catch (e) {
        return "";
      }
    },
    remover_articulo: function(row) {

      window.dialogLoader.show('Espere un momento por favor..');

      let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/delete_proveedor/" + row._id;
      let data = {
        id_proveedor: this.model._id,
        nombre_proveedor: this.model.nombre,
      };

      window.axios
        .post(url, data)
        .then(response => {
          if (response.data == true) {
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
              var new_arti = this.articulos.items.filter(x => x._id != row._id);
              this.articulos.items = new_arti;
          } else {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: response.data,
              footer: ""
            });
          }
        })
        .catch(error => {
          this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al remover el artículo. Intente nuevamente",
              footer: ""
            });
        }).then( ()=> {
          this.id_articulo = "";
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          window.dialogLoader.hide();
        });

    },
    seleccionar: function(row) {
        let existe = this.articulos.items.filter(x => x._id == row._id);

        if(existe.length <= 0) {

          window.dialogLoader.show('Espere un momento por favor..');

          let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + row._id;
          let data = {
            id_proveedor: this.model._id,
            nombre_proveedor: this.model.nombre,
          };

          window.axios
            .post(url, data)
            .then(response => {
              if (response.data == true) {
                  window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                    color: 'success'
                  });
                this.articulos.items.push(row);
              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: response.data,
                  footer: ""
                });
              }
            })
            .catch(error => {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al agregar el artículo. Intente nuevamente",
                  footer: ""
                });
            }).then( ()=> {
              window.dialogLoader.hide();
            });

        }
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (isLt2M) {
        return true;
      }
      this.$swal({
        type: "warning",
        title: "¡Operación no Permitida!",
        text: "El archivo no debe ser mayor a 2MB.",
        footer: ""
      });
      return false;
    },
    getProveedoresAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {"type":"proveedores"},
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.proveedores_all = response.data.docs;
            } else
              self.proveedores_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    getProveedoresExportarAll: function() {
      var self = this;
      window.dialogLoader.show('Espere un momento por favor..');  
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {"type":"proveedores"},
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            self.proveedores_all = self.camposLayout;
            if (response.data.docs.length > 0) {
              self.proveedores_all = self.proveedores_all.concat(response.data.docs);
            }
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          }).then(()=>{
            var btn = document.getElementById('botonExportarProveedores');
              if(btn){
                btn.click();
              }
              window.dialogLoader.hide();
          });
      });
    },
    getProveedoresArticulosAll: function() {
      var self = this;
      self.articulos_relacion = self.camposLayoutArticulos;
      window.dialogLoader.show('Espere un momento por favor..');         
          self.getProveedoresAll().then(result => {
            self.getArticulosConProveedor().then(result => {
              
                //TODO: Por cada proveedor en el articulo se agrega un registro
                for(var k in self.articulos_all){
                  var art = self.articulos_all[k];
                  for(var kp in art["proveedores"]){
                    self.articulos_relacion.push({
                      "rfc": self.getRFCProveedor(art["proveedores"][kp].id_proveedor),
                      "codigo_barras": art["codigo_barras"][0]
                    })
                  }
                }
              
            }).catch(error => {
              window.dialogLoader.hide();
              self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener los artículos.",
                footer: ""
              });
            }).then(()=>{
              var btn = document.getElementById('botonExportarRelacion');
              if(btn){
                btn.click();
              }
              window.dialogLoader.hide();
            });
          }).catch(error => {
            window.dialogLoader.hide();
            self.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los proveedores.",
              footer: ""
            });
          });
    },
    getArticulosActivos: function(opcion=0) {
      var self = this;
      return new Promise(function(resolve, reject) {
        window.dialogLoader.show('Espere un momento por favor..');
        
          var data = {
            "selector": {"type":"articulos"},
            "fields":["_id", "nombre", "descripcion","codigo_barras","categoria"],
          };
        
        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.articulos_lista_all = response.data.docs;
              for(var k in self.articulos_lista_all){
                self.articulos_lista_all[k]["categoria"] = self.articulos_lista_all[k]["categoria"].join(", ");
              }
            } else
              self.articulos_lista_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          }).then(()=>{
            window.dialogLoader.hide();
          });
      });
    },
    getArticulosAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {"type":"articulos"},
          "fields":["_id", "nombre", "descripcion","codigo_barras","categoria"],
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.articulos_all = response.data.docs;
            } else
              self.articulos_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    getArticulosConProveedor: function() {
      var self = this;
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {"type":"articulos",
            "proveedores.0": {
              "$exists": true
            }},
          "fields":["_id", "proveedores","codigo_barras"],
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.articulos_all = response.data.docs;
            } else
              self.articulos_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    verificaObligatorios: function(results, camposObligatorios) {
      var errores = '';
      var keys = [];
      for (var k in results) {
        keys.push(k);
      }

      camposObligatorios.forEach(function(campo) {
        if (keys.indexOf(campo) == -1)
          errores += '<br/>' + "- El campo '" + campo + "' es obligatorio.";
      });

      return errores == '' ? true : errores;
    },
    validaCargaLayoutProveedores: async function(results) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var errores = 0;
        var erroresText = '';
        var errorTmp = '';
        const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
        const patternEntero = /^[\d]*$/;
        const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        try {
          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 5000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 5000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatorios);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                //Si los opcionales vienen vacíos se ponen en blanco
                if (results[k]['telefono_empresa_1'] == null)
                  results[k]['telefono_empresa_1'] = "";
                if (results[k]['telefono_empresa_2'] == null)
                  results[k]['telefono_empresa_2'] = "";
                if (results[k]['telefono_2'] == null)
                  results[k]['telefono_2'] = "";
                if (results[k]['telefono_3'] == null)
                  results[k]['telefono_3'] = "";
                if (results[k]['supervisor'] == null)
                  results[k]['supervisor'] = "";
                if (results[k]['gerente'] == null)
                  results[k]['gerente'] = "";
                if (results[k]['email2'] == null)
                  results[k]['email2'] = "";
                if (results[k]['email3'] == null)
                  results[k]['email3'] = "";
                if (results[k]['numero_interior'] == null)
                  results[k]['numero_interior'] = "";

                if (!["Activo", "Inactivo"].includes(results[k]['estatus'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El estatus " + results[k]['estatus'] + " no es un estatus válido (Activo|Inactivo).";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                if (!patternFloat.test(results[k]['plazo'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El plazo " + results[k]['plazo'] + " no es una cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

                if (!patternEmail.test(results[k]['email'])) {
                  errores++;
                  errorTmp = '<br/>' + "- El email " + results[k]['email'] + " no es válido.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    validaProveedorImport: function(val) {
      var filter = this.proveedores_all.find(e => e.rfc.toString().toLowerCase().trim() == val.toString().toLowerCase().trim());
      if (filter)
        return filter;
      else
        return "";
    },
    getRFCProveedor: function(id_proveedor) {
      var filter = this.proveedores_all.find(e => e._id == id_proveedor);
      if (filter)
        return filter.rfc;
      else
        return "";
    },
    validaArticuloImport: function(val) {
      var filter = this.articulos_all.find(function(e){
          return  e.codigo_barras.includes(val.toString().toUpperCase().trim());
        }
      );
      if (filter){
        return filter;
      } else
        return "";
    },
    handleSuccessProveedores: async function({
      results,
      header
    }) {
      var errores = 0;
      var erroresText = '';
      var importData = {};
      try {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
            self.getProveedoresAll().then(result => {
                self.validaCargaLayoutProveedores(results).then(validaciones => {
                  window.dialogLoader.show('Espere un momento por favor..');

                  if (validaciones.errores > 0) {
                    this.$swal({
                      type: "warning",
                      title: "¡Operación no Permitida!",
                      text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                      footer: validaciones.erroresText,
                    });
                    window.dialogLoader.hide();
                  } else {
                    for (var k in results) {
                      //Buscar codigo; si ya existe, traerse _id y _rev para editar
                      var rfc = results[k]['rfc'].toString().trim().toUpperCase();
                      if (importData[rfc] != null) { //Si ya estaba en la colección (Significa que lo pusieron 2 o más veces en el excel) se actualiza, tomará los datos del último
                        var x = 1; //No se hace nada, se reemplaza la info mas adelante
                      } else {
                        var proveedorBuscado = self.validaProveedorImport(rfc);
                        if (proveedorBuscado != "") { //Ya existe, hay que actualizar
                          importData[rfc] = proveedorBuscado;
                        } else {//es nuevo
                          importData[rfc] = {};
                          importData[rfc].type = "proveedores";
                          importData[rfc].fecha_alta = window.moment().format("YYYY-MM-DDTHH:mm:ss");                                                                              
                        }
                      }

                      importData[rfc].nombre = results[k]['nombre'].toString().trim();
                      importData[rfc].rfc = rfc;
                      importData[rfc].telefono_empresa_1 = results[k]['telefono_empresa_1'].toString().trim();
                      importData[rfc].telefono_empresa_2 = results[k]['telefono_empresa_2'].toString().trim();
                      importData[rfc].giro = results[k]['giro'].toString().trim();
                      
                      
                      importData[rfc].contacto = results[k]['contacto'].toString().trim();
                      importData[rfc].telefono_1 = results[k]['telefono_1'].toString().trim();
                      importData[rfc].email = results[k]['email'].toString().trim();
                      importData[rfc].supervisor = results[k]['supervisor'].toString().trim();
                      importData[rfc].telefono_2 = results[k]['telefono_2'].toString().trim();
                      importData[rfc].email2 = results[k]['email2'].toString().trim();
                      importData[rfc].gerente = results[k]['gerente'].toString().trim();
                      importData[rfc].telefono_3 = results[k]['telefono_3'].toString().trim();
                      importData[rfc].email3 = results[k]['email3'].toString().trim();

                      
                      importData[rfc].plazo = results[k]['plazo'].toString().trim();
                      importData[rfc].condiciones = results[k]['condiciones'].toString().trim();
                      
                      importData[rfc].domicilio = results[k]['domicilio'].toString().trim();
                      importData[rfc].numero_exterior = results[k]['numero_exterior'].toString().trim();
                      importData[rfc].numero_interior = results[k]['numero_interior'].toString().trim();
                      importData[rfc].colonia = results[k]['colonia'].toString().trim();
                      importData[rfc].ciudad = results[k]['ciudad'].toString().trim();
                      importData[rfc].codigo_postal = results[k]['codigo_postal'].toString().trim();
                      importData[rfc].estatus = results[k]['estatus'].toString().trim();

                    }

                    var bulkDatos = [];
                    for (var key in importData) {
                      bulkDatos.push(importData[key]);
                    }

                    var docs = {
                      "docs": bulkDatos
                    };
                    window.axios
                      .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                      .then(response => {
                        self.$swal({
                          type: "success",
                          title: "¡Operación Exitosa!",
                          text: "Los datos se guardaron correctamente.",
                          footer: "",
                        });
                        self.modalImportar = false;
                        window.dialogLoader.hide();
                        self.items = [];
                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                      })
                      .catch(error => {
                        self.$swal({
                          type: "warning",
                          title: "¡Operación no Permitida!",
                          text: "Ocurrió un error al importar la plantilla. Favor de intentar nuevamente.",
                          footer: "",
                        });
                        window.dialogLoader.hide();
                      });

                  }
                }).catch(error => {
                  self.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo",
                    footer: error,
                  });
                  console.log(error);
                  window.dialogLoader.hide();

                });

            }).catch(error => {
              window.dialogLoader.hide();
              self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener los proveedores.",
                footer: ""
              });
            });


      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }
    },

    validaCargaLayoutArticulosProveedores: async function(results) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var errores = 0;
        var articulos_encontrados = [];
        var erroresText = '';
        var errorTmp = '';
        const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
        const patternEntero = /^[\d]*$/;
        try {
          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 5000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 5000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatoriosArticulos);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                var articulos_validos = {};
                var proveedores_validos = {};

                var rfc = results[k]['rfc'].toString().trim().toUpperCase();
                var codigo = results[k]['codigo_barras'].toString().trim().toUpperCase();
                results[k]["proveedor"] = null;
                results[k]["id_articulo"] = null;



                if( proveedores_validos[rfc] != null){
                  results[k]["proveedor"] = proveedores_validos[rfc];
                } else {
                    var proveedorBuscado = self.validaProveedorImport(rfc);
                    if (proveedorBuscado != "") {
                      var record = {"id_proveedor":proveedorBuscado._id , "nombre_proveedor":proveedorBuscado.nombre};
                      proveedores_validos[rfc] = record;
                      results[k]["proveedor"] = record;
                    } else {
                      errores++;
                      errorTmp = '<br/>' + "- El proveedor " + rfc + " no se encuentra registrado.";
                      if (erroresText.indexOf(errorTmp) == -1)
                        erroresText += errorTmp;
                    }
                }

                if( articulos_validos[codigo] != null){
                  results[k]["id_articulo"] = articulos_validos[codigo];
                } else {
                    var articuloBuscado = self.validaArticuloImport(codigo);
                    if (articuloBuscado != "") {
                      articulos_validos[codigo] = articuloBuscado._id;
                      results[k]["id_articulo"] = articuloBuscado._id;
                    } else {
                      errores++;
                      errorTmp = '<br/>' + "- El articulo " + codigo + " no se encuentra registrado.";
                      if (erroresText.indexOf(errorTmp) == -1)
                        erroresText += errorTmp;
                    }
                }

                if(results[k]["proveedor"] != null && results[k]["id_articulo"] != null){
                  var r = {"proveedor": results[k]["proveedor"], "id_articulo": results[k]["id_articulo"]};
                  articulos_encontrados.push(r);
                }

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText,
            "articulos": articulos_encontrados,
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    handleSuccessArticulosProveedores: async function({
      results,
      header
    }) {
      var errores = 0;
      var erroresText = '';
      var importData = {};
      try {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
            self.getProveedoresAll().then(result => {
              self.getArticulosAll().then(result => {
                self.validaCargaLayoutArticulosProveedores(results).then(async validaciones => {
                  window.dialogLoader.show('Espere un momento por favor..');

                  if (validaciones.errores > 0) {
                    this.$swal({
                      type: "warning",
                      title: "¡Operación no Permitida!",
                      text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                      footer: validaciones.erroresText,
                    });
                    window.dialogLoader.hide();
                  } else {
                    window.dialogLoader.show('Espere un momento por favor..');

                    for (var k in validaciones.articulos) {
                      var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + validaciones.articulos[k].id_articulo;
                      var data = validaciones.articulos[k].proveedor;

                      await window.axios
                        .post(url, data).then(response => {

                        }).catch(error => {
                          errores += 1;
                          console.log(error);
                        });
                    }

                    if (errores > 0){
                        self.$swal({
                          type: "warning",
                          title: "¡Operación no Permitida!",
                          text: "Ocurrió un error al importar la plantilla. Favor de intentar nuevamente.",
                          footer: "",
                        });
                        window.dialogLoader.hide();
                    } else {
                      self.$swal({
                          type: "success",
                          title: "¡Operación Exitosa!",
                          text: "Los datos se guardaron correctamente.",
                          footer: "",
                        });
                        self.modalImportar = false;
                        window.dialogLoader.hide();
                        self.items = [];
                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    }

                  }
                }).catch(error => {
                  self.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo",
                    footer: error,
                  });
                  console.log(error);
                  window.dialogLoader.hide();

                });
              }).catch(error => {
                window.dialogLoader.hide();
                self.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al obtener los artículos.",
                  footer: ""
                });
              });
            }).catch(error => {
              window.dialogLoader.hide();
              self.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener los proveedores.",
                footer: ""
              });
            });


      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }
    },
  }
}
</script>
<style scoped>

</style>
